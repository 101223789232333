import movinglogo from "app/assets/images/svg/movinglogo.svg";

import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import {
  Link,
  Links,
  Meta,
  NavLink,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useParams,
  useRouteError
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { KeyRound, MessageCircle, Terminal } from "lucide-react";
import "./styles/fontface.css";

import Header from "@/components/Header/Header";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Role } from "@prisma/client";
import { getUser } from "~/session.server";
import { countUnreadNotesForUser } from "./models/note.server";
import { getUserRoles } from "./models/user.server";
import "./styles/tailwind.css";

export const meta: MetaFunction = () => [
  {
    title: "Amanda AI",
  },
];

export const links: LinksFunction = () => {
  return [
    // add favicon
    {
      rel: "icon",
      href: "/favicon.svg",
      type: "image/svg+xml",
    },
  ];
  // return [{ rel: "icon", href: "https://c1.webien.cloud/amandaai.com-stage/app/uploads/2022/08/favicon-logo-ornage.svg", type: "image/svg+xml" }];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {

  // fetch build number
  const fs = await import('fs');
  const path = await import('path');
  const filePath = path.join(process.cwd(), 'buildNumber.txt');
  let buildNumber = "";

  if (fs.existsSync(filePath)) {
    buildNumber = String(fs.readFileSync(filePath, 'utf8'));
  }

  const url = new URL(request.url);
  const pathName = url.pathname
  const user = await getUser(request);

  if (!user && pathName !== "/login" && !/^\/recover/.test(pathName)) {
    console.log("redirecting to login", pathName);
    throw redirect("/login");
  }

  let isAdmin = false;
  let undreadNotesCount = 0;
  let userRoles: Role[] | undefined = [];
  if (user) {
    userRoles = await getUserRoles(user?.id);
    if (userRoles && (userRoles.includes(Role.ADMIN) || userRoles.includes(Role.PARTNERADMIN) || userRoles.includes(Role.SUPERUSER))) {
      isAdmin = true;
    }
    undreadNotesCount = await countUnreadNotesForUser({ userId: user?.id });
  }

  return json(
    {
      user,
      isAdmin,
      undreadNotesCount,
      buildNumber
    },
  );
};

function App() {
  const { user, isAdmin, undreadNotesCount, buildNumber } = useLoaderData<typeof loader>();
  const params = useParams();
  const organizationId = params?.organizationId;
  const location = useLocation();
  const pathname = location.pathname;
  const isOnboarding = pathname.includes("/onboarding");

  return (
    <html lang="en" className="h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="flex flex-col m-0 p-0 box-border min-h-screen">
        <div className="w-full flex flex-col m-0 p-0 box-border min-h-screen">
          {user ? (
            <Header user={user}>
              {!isOnboarding ? (
                <div className="mr-2 hidden space-x-3 md:flex items-center">
                  {isAdmin ? (
                    <NavLink
                      to={"/office"}
                      key="office"
                      className={({ isActive }) =>
                        `hover:text-underline leading-haus tracking-haus block rounded-full p-2 px-3 text-sm font-semibold uppercase ${isActive
                          ? "border border-hausbeigelight bg-hausbeigelight text-hausorange"
                          : ""
                        }`
                      }
                      viewTransition
                      prefetch="intent"
                    >
                      <div className="relative">
                        <div className="h-6 w-6 absolute -top-5 -right-5 inline-flex justify-center items-center rounded-full bg-hausorange p-1 text-black text-opacity-70">
                          <KeyRound className="inline-flex text-white text-opacity-75" />
                        </div>
                        Office
                      </div>
                    </NavLink>)
                    : null
                  }
                  <NavLink
                    to={organizationId ? `/projects/${organizationId}` : `/projects`}
                    key="projects"
                    className={({ isActive }) =>
                      `hover:text-underline leading-haus tracking-haus block rounded-full p-2 px-3 text-sm font-semibold uppercase ${isActive
                        ? "border border-hausbeigelight bg-hausbeigelight text-hausorange"
                        : ""
                      }`
                    }
                    viewTransition
                    prefetch="intent"
                  >
                    Projects
                  </NavLink>
                  <NavLink
                    to={organizationId ? `insights/${organizationId}/performance-overview` : `insights/${organizationId}/performance-overview`}
                    key="insights"
                    className={({ isActive }) =>
                      `hover:text-underline leading-haus tracking-haus block rounded-full p-2 px-3 text-sm font-semibold uppercase ${isActive
                        ? "border border-hausbeigelight bg-hausbeigelight text-hausorange"
                        : ""
                      }`
                    }
                    viewTransition
                    prefetch="intent"
                  >
                    Insights
                  </NavLink>
                  <NavLink
                    to="drafts"
                    key="drafts"
                    className={({ isActive }) =>
                      `hover:text-underline leading-haus tracking-haus block rounded-full p-2 px-3 text-sm font-semibold uppercase ${isActive
                        ? "border border-hausbeigelight bg-hausbeigelight text-hausorange"
                        : ""
                      }`
                    }
                    viewTransition
                    prefetch="intent"
                  >
                    Drafts
                  </NavLink>
                  <div className="relative mr-1 flex items-center gap-5">
                    <div>
                      <Link to="office/notes" viewTransition prefetch="intent" className="group">
                        <MessageCircle size={22} className="group-hover:text-hausorange transition-all duration-500" />
                        {undreadNotesCount > 0 ? (
                          <div className="absolute -top-1 -right-1 flex text-xs h-4 w-4 bg-hausorange rounded-full items-center justify-center transform-gpu transition-transform duration-300 delay-75 ease-in group-hover:animate-bounce">
                            {undreadNotesCount}
                          </div>)
                          : null}
                      </Link>
                    </div>
                  </div>
                </div>
              ) :
                null
              }
            </Header>
          ) : null}
          <main className="h-full grow">
            <Outlet />
          </main>
          <footer className="bg-black relative z-50 grow-0">
            {!user ? (
              <div className="bg-hausorange px-8 pt-20 pb-48 z-30 flex-1">
                <div className="grid grid-cols-4 mx-auto max-w-7xl">
                  <div className="col-span-4">
                    <div className="py-8 border-t border-b border-black">
                      <img src={movinglogo} alt="Amanda AI logo" className="w-24" />
                    </div>
                    <div className="border-b border-black py-7 grid gap-3">
                      <h4 className="hauslabel text-xs">Our philosophy:</h4>
                      <p className="text-5xl font-medium w-80">
                        Dance to the algorithms!
                      </p>
                    </div>
                  </div>
                  <div className="col-end-5 text-right">
                    <Link to="https://amandaai.com/privacy-policy/" target="_blank" className="text-black font-light self-end justify-end items-end">Privacy policy</Link>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="text-white text-center pt-5 pb-7">
              <div className="text-xs font-thin">Amanda AI © 2024 </div>
              <div className="mr-1 text-muted-foreground text-[8pt] font-thin">{(`build.${buildNumber}`)}</div>
            </div>
          </footer>
        </div>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();

  if (error instanceof Error) {
    return (
      <div className="h-screen">
        <Alert variant="destructive">
          <Terminal className="h-4 w-4" />
          <AlertTitle>An unexpected error occurred</AlertTitle>
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      </div>
    );
  }

  if (!isRouteErrorResponse(error)) {
    return (
      <Alert variant="destructive">
        <Terminal className="h-4 w-4" />
        <AlertTitle>An unknown error occurred</AlertTitle>
      </Alert>
    );
  }

  captureRemixErrorBoundaryError(error);

  return (
    <Alert variant="destructive">
      <Terminal className="h-4 w-4" />
      <AlertTitle>An unexpected error occurred</AlertTitle>
      <AlertDescription>{error.data}</AlertDescription>
    </Alert>
  );
}
